.orderSummaryLabel {
  align-self: flex-start !important;
}

.orderSummary {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  column-gap: 1em;
}

.orderSummary label {
  margin: 0 !important;
}
.orderSummary label.fullSpan {
  grid-column: 1/-1;
}
