.counter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
}
.counter button {
  width: 3em;
  height: 3em;
  margin: 0 auto 0;
}

.counter label {
  text-align: center;
  font-size: 1.5em;
}
