.image-preview-wrapper {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid var(--color);
  margin: 0 calc(var(--spacing) / 2) 0 0;
  width: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.image-preview-wrapper .image-preview {
  display: flex;
  min-width: 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.image-preview img {
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.image-preview a {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  color: var(--del-color);
}
