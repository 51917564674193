.reports {
  display: grid;
  grid-template-columns: 200px minmax(200px, 1fr);
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing);
}
.reports h4 {
  grid-column: 1/-1;
  margin-bottom: 0;
  margin-top: var(--spacing);
}
.reports > label {
  margin-bottom: 0;
  max-width: 200px;
  grid-column: 1;
}
.reports input[type='date'],
.reports input[type='number'],
.reports select,
.reports button {
  margin-bottom: 0;
  max-width: 200px;
  grid-column: 2;
}
.reports button {
  max-width: unset;
}
.reports .spacer {
  grid-column-end: -1;
}

.reports span input {
  width: calc(4 * var(--spacing)) !important;
  margin-bottom: 0 !important;
  margin-right: var(--spacing);
}

.reports > button {
  max-width: 200px;
}
.reports hgroup,
.reports progress {
  grid-column: 1/-1;
}

@media screen and (max-width: 970px) {
  .reports > label {
    grid-column: 1/-1;
  }
}
