.multi-string-input {
  display: grid;
  grid-template-columns: 1fr 3em;
  row-gap: calc(var(--spacing) / 4);
  align-items: center;
  justify-content: flex-start;
}

.multi-string-input button,
.multi-string-input a {
  width: 3em;
  height: 3em;
}

.multi-string-input button,
.multi-string-input p {
  margin-bottom: 0;
}

.multi-string-input a {
  display: flex;
  align-items: center;
  justify-content: center;
}
