.baitOrderItems {
  grid-column: 1/-1;
}

.baitOrderItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: flex-start;
  margin: calc(var(--spacing) / 1.5) 0;
}

.baitOrderItem label {
  margin-bottom: 0 !important;
}

.notes-label {
  align-self: start;
  padding-top: var(--pico-form-element-spacing-vertical);
}
