:root {
  --brolos-blue: #003768;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

dialog article > header {
  padding-top: calc(0.5 * var(--spacing));
  padding-bottom: calc(0.5 * var(--spacing));
}

dialog article > header .close {
  margin: 0;
  margin-left: var(--spacing);
  float: right;
  margin-right: calc(0.5 * var(--spacing));
  position: absolute;
  right: 0;
  top: calc(0.5 * var(--spacing));
}

dialog article > header h3 {
  margin: 0;
  position: relative;
}

dialog article > footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: calc(0.5 * var(--spacing));
}

dialog article > footer button,
dialog article > footer [role='button'] {
  padding: calc(0.5 * var(--spacing));
  margin: 0;
  flex-basis: 100px;
  text-decoration: none;
}

.input-btn-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.input-btn-right button {
  width: 3em;
  height: 3em;
  margin: 0;
}

.filter-row {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  align-items: center;
  justify-content: flex-start;
  column-gap: var(--spacing);
  position: sticky;
  top: var(--header-height);
  z-index: 5;
}

.filter-row label,
.filter-row a {
  grid-row: 1;
}

.filter-row input,
.filter-row select,
.filter-row details {
  grid-row: 2;
  margin-bottom: 0 !important;
}

.filter-row details [role='listbox'] {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
}

.homeLink {
  width: calc(100% + 2em);
  min-height: 96px;
  background-color: var(--brolos-blue);
  position: relative;
  z-index: 1;
  display: block;
  margin-left: -1em;
  margin-top: -1em;
  margin-bottom: 1em;
}

.homeLink svg {
  padding: 1em;
  z-index: 2;
  display: block;
}

tbody tr:hover td {
  background-color: var(--brolos-blue);
  color: white;
}

.error-text {
  color: red;
}

.pwd-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--form-element-border-color);
  border-radius: 5px;
  padding-right: 10px;
}

.pwd-field:focus-within {
  border-color: #1095c1;
  box-shadow: 0px 0px 5px #1095c120;
}

.pwd-field > input {
  margin-bottom: 0;
  box-shadow: none;
  border: none;
  outline: none;
  background: transparent;
}
