.modal article {
  max-width: 900px;
  width: 50%;
}
.modal article footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--spacing);
}
.modal article footer a {
  margin-right: var(--spacing);
}

.modal article p {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-end;
  --spacing: 8px;
  gap: var(--spacing);
}
.modal article p label,
.modal article p input[type],
.modal article p select {
  margin: 0;
}
.modal article p input[type='checkbox'] {
  margin-right: var(--spacing);
}
.modal article p > label {
  grid-column: 1;
}
.modal p label.error {
  color: var(--pico-form-element-invalid-border-color);
}
.modal p label.error.msg {
  grid-column: 2;
  font-size: smaller;
}
.modal p label.error + input,
.modal p label.error + span input,
.modal p label.error + select,
.modal p label.error + span select {
  color: var(--pico-form-element-invalid-border-color);
  border-color: var(--pico-form-element-invalid-border-color);
}
