#landing-schedules {
  hgroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  h2 {
    flex: 1;
  }
  div {
    display: flex;
    flex-direction: row;
    flex: 2;
    align-items: center;
    justify-content: flex-start;
    label {
      width: 160px;
      text-align: right;
    }
    input,
    select {
      flex: 0;
      margin-right: 1em;
      margin-left: 1em;
    }
    select {
      width: 260px;
    }
  }
}
