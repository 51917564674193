#root {
  --header-height: 116px;
  --filter-top: 95px;
  --filter-table-top: calc(var(--filter-top) + 102px);
  --spacing: 8px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: var(--header-height) 1fr auto;
  overflow: hidden;

  & > header {
    grid-row: 1;
    grid-column: 2/-1;
    height: var(--header-height);
    background-color: var(--brolos-blue);
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      color: white;
    }
  }

  & > aside {
    grid-row: 1/-1;
    grid-column: 1;
    padding: 1em;
    position: relative;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--header-height) 1fr;

    nav {
      z-index: 1;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .active a {
      --background-color: var(--primary-focus);
      --color: var(--primary-hover);
    }

    &::before {
      position: absolute;
      left: -16px;
      top: -16px;
      bottom: -16px;
      right: 10px;
      content: '';
      background-image: url('../public/assets/images/login_bg_4.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(8px);
    }

    &:after {
      position: absolute;
      left: -16px;
      top: -16px;
      bottom: -16px;
      right: 0px;
      content: '';
      background-color: rgba(#000, 0.5);
    }
  }

  & > .content {
    grid-row: 2;
    grid-column: 2;
    padding: 0 1em 1em;
    overflow: hidden auto;

    hgroup {
      position: sticky;
      top: 0;
      background-color: var(--pico-background-color);
      z-index: 2;
      padding-top: 1em;
      padding-bottom: var(--spacing);
      margin-bottom: 0;

      // sticky filter bar
      & + .filter-row {
        position: sticky;
        top: var(--filter-top);
        z-index: 5;
        background: var(--pico-background-color);

        // sticky table header when  filter bar
        & + table {
          z-index: 0;

          thead tr:first-of-type {
            position: sticky;
            top: var(--filter-table-top);
            background: var(--pico-background-color);
          }
        }
      }

      // sticky table header when no filter bar
      & + table {
        z-index: 1;

        thead tr:first-of-type {
          position: sticky;
          top: var(--filter-top);
          background: var(--pico-background-color);
        }
      }
    }

    table {
      thead {
        z-index: 2;

        tr {
          z-index: 2;
        }
      }

      tbody {
        z-index: unset;
      }

      tr {
        td:last-child {
          a {
            display: inline-block;

            &:not(:last-of-type) {
              margin-right: calc(var(--spacing) * 0.5);
            }
          }
        }
      }
    }
  }

  & > footer {
    grid-row: 3;
    grid-column: 2/-1;
    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 0.5em;
    }
  }
}

label > input[type='checkbox'] {
  margin-left: var(--spacing);
}

.env-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 1.5rem;
  font-weight: bolder;
  border: 0.2rem solid black;
  border-radius: 0.75rem;
  padding: 0.25rem;
  background: red;
}

.sticky-page-header {
  position: sticky;
}

.float-right {
  float: right;
}

.trip-summary {
  font-size: 0.75em;
}

@media screen and (min-width: 360px) and (max-width: 767px) {
  #root {
    display: block;

    aside {
      display: none;
    }
  }
}
